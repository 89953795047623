export type Appearance = "light" | "dark";

export type User = {
  id: string;
  clientId: string;
  name: string;
  countryCode: string;
  city: string;
  zipCode: string;
  address1: string;
  ssn: string;
  languageCode: string;
  email: string;
  phone: string;
  b2: string;
  distribution: number;
  ledger: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
};

export type InvoiceItem = {
  id: number;
  subjectMatter: string;
  name: string;
  price: string;
  quantity: number;
  period: { value: string; inclusive: boolean }[];
  _deletedIntentionally: boolean | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  invoiceId: number;
  unitPrice: number;
  totalAmount: string;
};

export type TimelineEvent = {
  date: string;
  type: string;
  amount?: string;
  currency?: string;
};

export enum InvoiceStatus {
  open = "open",
  paused = "paused",
  closed = "closed",
  cancelled = "cancelled",
}

export enum InvoiceStage {
  invoiced = "invoiced",
  reminded = "reminded",
  debtCollection = "debtCollection",
}

export enum InvoicePaymentStatus {
  notPaid = "notPaid",
  partiallyPaid = "partiallyPaid",
  paid = "paid",
  overpaid = "overPaid",
}

export interface Invoice {
  id: string;
  invoiceNo: string;
  displayId: string;
  pdfTemplate: number;
  pdfURL: string;
  DITemplate: number;
  reminderTemplate: number;
  customerId: number;
  date: string;
  dueDate: string;
  currency: string;
  receiverCountryCode: string;
  receiverCity: string;
  receiverZipCode: string;
  receiverAddress1: string;
  receiverPhone: string;
  paymentReference: string;
  paymentStatus: InvoicePaymentStatus;
  description: string;
  totalAmount: string;
  status: InvoiceStatus;
  stage: InvoiceStage;
  VATAmount: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  invoiceItems: InvoiceItem[];
  histories: TimelineEvent[];
  reminderFee: string | undefined | null;
  bankName: string;
  IBAN: string;
}

export interface SwishPaymentRequest {
  invoiceId: string;
  payeePaymentReference: string;
  payeeAlias: "1233908639"; // hardcode
  amount: number;
  currency: string;
  callbackUrl: string;
  message?: string;
  deviceType: "mobile" | "desktop";
}

export interface SwishStatus {
  url: string;
  token: string;
  qrCode?: string;
  id: string;
  status?: number;
}

export interface SwishStatusResponse {
  status: string | number;
  dateCreated: string;
  id: string;
  paymentReference: string;
  errorCode: null;
  errorMessage: null;
  datePaid: string;
  amount: number;
  currency: string;
  error?: string;
  reason?: "declined";
}

export type PillIntent =
  | "success"
  | "error"
  | "danger"
  | "clear"
  | "info"
  | "notice"
  | "warning";

export type PaymentProviders = {
  type: "bank-transfer" | "swish";
  onOpenCallback?: () => void;
};

export enum Contrast {
  dark = "#000000",
  light = "#FFFFFF"
}