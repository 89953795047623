import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Text } from "components/Text";

import {
  useInitSwishPaymentMutation,
} from "lib/slices/paymentServiceSlice";

import styles from "./InvoicePaymentCard.module.scss";
import { CircularProgress } from "components/Progress/CircularProgress";

type Props = {
  price?: string;
  currency?: string;
  isOpen: boolean;
};

export function PaymentSwish({ price, currency }: Props) {
  const { t } = useTranslation();

  const [, res] = useInitSwishPaymentMutation({
    fixedCacheKey: "swish-payment",
  });

  const openSwishApp = (url: string) => {
    window.location.assign(url);
  };

  if (isMobile) {
    return (
      <div className={styles.paymentSwish}>
        <Text as="p" style="body" strong>
          {t("features.invoice.payment.swishPaying", { price, currency })}
        </Text>

        {res.isLoading ? (
          <div className={styles.spinner}>
            <CircularProgress color="#007EE5" spinnerSize="100px" stroke="8px" />
          </div>
        ) : (
          <Button
            text={t("features.invoice.payment.openSwishMobile")}
            intent="primary"
            onClick={() => openSwishApp(res.data?.url as string)}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.paymentSwish}>
      <Text as="p" style="body" strong>
        {t("features.invoice.payment.swishPaying", { price, currency })}
      </Text>
      <Text as="p" style="body">
        {t("features.invoice.payment.openSwish")}
      </Text>

      <div className={styles.imgContainer}>
        {res.data?.qrCode ? (
          <img src={`data:image/png;base64,${res.data.qrCode}`} />
        ) : (
          <CircularProgress color="#007EE5" spinnerSize="100px" stroke="8px" />
        )}
      </div>
    </div>
  );
}
