import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { CardAccordion } from "components/CardAccordion";
import { Icon } from "components/Icon";
import { Pill } from "components/Pill";
import { Text } from "components/Text";
import { Tooltip } from "components/Tooltip";

import { Invoice, PaymentProviders } from "lib/types";

import styles from "./InvoicePaymentCard.module.scss";
import { PaymentBankTransfer } from "./PaymentBankTransfer";
import { PaymentSwish } from "./PaymentSwish";

type PaymentMethods = {
  [key: string]: {
    title: string;
    provider: string;
    icon?: JSX.Element | string;
    disabled?: boolean;
    showProvider?: boolean;
    children?: JSX.Element;
  };
};

// Button to open the accordion
function ButtonOpenAccordion({
  title,
  provider,
  recommended,
  icon,
  businessDays,
  showProvider,
}: {
  title: string;
  provider: string;
  recommended?: boolean;
  icon?: string | JSX.Element;
  businessDays?: boolean;
  showProvider: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.paymentCard}>
      <div className={styles.top}>
        <div>
          <Text as="p" style="h5">
            {title}
          </Text>
          {recommended && (
            <Pill
              text={t("features.invoice.payment.recommended")}
              intent="clear"
            />
          )}
        </div>

        <>{typeof icon === "string" ? <img src={icon as string} /> : icon}</>
      </div>

      <div>
        <Text as="p" style="body-small" color="--static-content-muted">
          {showProvider && provider}
          {businessDays ? t("features.invoice.payment.businessDays") : ""}
        </Text>
      </div>
    </div>
  );
}

type Props = {
  providers: Array<PaymentProviders>;
  businessDays?: boolean;
  recommended?: boolean;
  price?: string;
  currency?: string;
  invoice: Invoice;
};

export function InvoicePaymentCard({
  providers,
  recommended,
  businessDays,
  price,
  currency,
  invoice,
}: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  // Open the accordion callback
  const accordionOpenCallback = (isOpen: boolean, callback?: () => void) => {
    setIsOpen(isOpen);

    if (isOpen && callback) {
      callback();
    }
  };

  // Payments methods
  const paymentsMethods: PaymentMethods = useMemo(
    () => ({
      "bank-transfer": {
        title: t("features.invoice.payment.bankTransfer"),
        provider: t("features.invoice.payment.bankTransfer"),
        disabled: true,
        showProvider: false,
        icon: (
          <Tooltip
            intent="light"
            text={
              <div>
                <Text as="h5" style="body-small" strong>
                  {t("features.invoice.payment.bankTransferTooltipHeading")}
                </Text>
                <Text as="h5" style="body-small">
                  {t("features.invoice.payment.bankTransferTooltipText")}
                </Text>
              </div>
            }
          >
            <Icon name="infoCircle" size={18} />
          </Tooltip>
        ),
        children: (
          <PaymentBankTransfer
            bankName={invoice?.bankName}
            paymentReference={invoice?.paymentReference}
            iban={invoice?.IBAN}
          />
        ),
      },
      swish: {
        title: t("features.invoice.payment.directPayment"),
        provider: t("features.invoice.payment.swish"),
        showProvider: true,
        icon: "/assets/swish.svg",
        children: (
          <PaymentSwish price={price} currency={currency} isOpen={isOpen} />
        ),
      }
    }),
    [],
  );

  const filteredPaymentMethods = Object.entries(paymentsMethods).filter(
    ([key]) => {
      return providers.find((provider) => provider.type === key);
    },
  );

  if (filteredPaymentMethods.length === 0) {
    return (
      <>
        {/* This message should not be shown but as a fallback content for future cases */}
        <h2>No payment methods provided</h2>
      </>
    );
  }

  return filteredPaymentMethods.map(([provider]) => {
    // If the provider has children, render the CardAccordion
    if (paymentsMethods[provider].children) {
      return (
        <CardAccordion
          key={provider}
          btn={
            <>
              <ButtonOpenAccordion
                title={paymentsMethods[provider].title}
                provider={paymentsMethods[provider].provider}
                recommended={recommended}
                icon={paymentsMethods[provider].icon}
                businessDays={businessDays}
                showProvider={paymentsMethods[provider].showProvider ?? true}
              />
            </>
          }
          onChange={accordionOpenCallback}
          onOpen={providers.find((el) => el.type === provider)?.onOpenCallback}
          disabled={paymentsMethods[provider].disabled}
        >
          {paymentsMethods[provider]?.children ?? <></>}
        </CardAccordion>
      );
    }

    // Otherwise show just the button - Do we need this?
    return (
      <Card key={provider} noPadding>
        <Button intent="clear" fill>
          <ButtonOpenAccordion
            title={paymentsMethods[provider].title}
            provider={paymentsMethods[provider].provider}
            recommended={recommended}
            icon={paymentsMethods[provider].icon}
            businessDays={businessDays}
            showProvider={true}
          />
        </Button>
      </Card>
    );
  });
}
