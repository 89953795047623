import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { CardInfo } from "components/CardInfo";
import { Pill } from "components/Pill";
import { Text } from "components/Text";

import {
  convertKebabIntoCamelCased,
} from "lib/helpers";
import {
  InvoicePaymentStatus,
  InvoiceStage,
  InvoiceStatus,
  PillIntent,
} from "lib/types";

import styles from "./InvoiceHero.module.scss";
import { pillIntentStatusMapper } from "lib/mappers";

export type UnpaidInvoice = {
  id: string;
  displayId: string;
  dueDate: string;
  amount: string;
  currency: string;
};

function RenderInvoiceCard({
  invoiceTitle,
  displayId,
  invoiceId,
  statusText,
  pillIntent,
  children,
}: {
  invoiceTitle: string;
  invoiceId: string;
  displayId: string;
  statusText: string;
  pillIntent?: PillIntent;
  children: JSX.Element;
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <div className={styles.header}>
        <div className={styles.title}>
          <div>
            <Text as="p" style="h2">
              {invoiceTitle}
            </Text>

            <Pill text={statusText} intent={pillIntent} />
          </div>
          {displayId && (
            <Text as="p" style="body">
              {t("features.invoice.invoiceNumber")} {displayId}
            </Text>
          )}
        </div>

        <Link to={`/invoice/${invoiceId}/details`}>
          <Text as="p" style="body-small">
            {t("features.invoice.details")}
          </Text>
        </Link>
      </div>

      {children}
    </Card>
  );
}

type Props = {
  invoiceTitle: string;
  invoiceId: string;
  displayId: string;
  amount: string;
  currency: string;
  dueDate: string;
  reminderFee?: string | null;
  invoicePaymentStatus: InvoicePaymentStatus;
  invoiceStatus: InvoiceStatus;
  invoiceStage: InvoiceStage;
};

export function InvoiceHero({
  invoiceId,
  invoiceTitle,
  displayId,
  amount,
  currency,
  dueDate,
  invoicePaymentStatus,
  invoiceStatus,
  invoiceStage,
}: Props) {
  const { t } = useTranslation();

  let status;

  if (invoiceStatus === InvoiceStatus["cancelled"]) {
    status = "cancelled";
  } else {
    status = invoicePaymentStatus;
  }

  const statusText = t(
    `features.invoice.backendStatusTextTranslationMapping.${convertKebabIntoCamelCased(status)}`,
  );

  let pillIntent: PillIntent;

  if (invoiceStatus === InvoiceStatus["open"]) {
    if(invoicePaymentStatus === InvoicePaymentStatus["notPaid"] && invoiceStage === InvoiceStage["invoiced"]) {
      pillIntent = "clear";
    } else {
      pillIntent = pillIntentStatusMapper[invoicePaymentStatus];
    }
  } else {
    pillIntent = "clear";
  }

  return (
    <RenderInvoiceCard
      invoiceTitle={invoiceTitle}
      displayId={displayId}
      invoiceId={invoiceId}
      statusText={statusText}
      pillIntent={pillIntent}
    >
      <div className={styles.content} style={{ marginBottom: 4 }}>
        {invoicePaymentStatus === InvoicePaymentStatus["partiallyPaid"] && (
          <Text as="p" style="h5">
            Remaining
          </Text>
        )}
        <Text as="h2" style="h1">
          {currency.toLowerCase() === "eur" ? (
            <>
              {amount} {currency}
            </>
          ) : (
            <>
              {currency} {amount}
            </>
          )}
        </Text>

        {/* Due date */}
        {invoiceStage !== InvoiceStage["debtCollection"] &&
        invoicePaymentStatus !== InvoicePaymentStatus["paid"] &&
        invoiceStatus !== InvoiceStatus["cancelled"] && (
          <div className={styles.dueDate}>
            <span className={styles.alertCircle} />
            <Text as="p" style="body" color="--static-content-subtle">
              {t("features.invoice.dueDate", { date: dueDate })}
            </Text>
          </div>
        )}

        {invoiceStatus === InvoiceStatus["open"] && (
          <>
            {/* Warning messages */}
            {invoicePaymentStatus !== InvoicePaymentStatus["paid"] 
              && invoiceStage === InvoiceStage["reminded"] 
              &&
                (
                  <CardInfo
                    text={t("features.invoice.warningMessages.warning", {
                      date: dueDate,
                    })}
                    intent="warning"
                    withWarning
                    customClasses={[styles.warning]}
                  />
                )}

            {invoicePaymentStatus !== InvoicePaymentStatus["paid"] 
              && invoiceStage === InvoiceStage["debtCollection"] 
              && (
                <CardInfo
                  text={t("features.invoice.warningMessages.debtCollection")}
                  intent="danger"
                  withWarning
                  customClasses={[styles.warning]}
                />
              )}
          </>
        )}

        {/* Pay button */}
        {invoiceStatus !== InvoiceStatus["cancelled"] 
          && invoiceStage !== InvoiceStage["debtCollection"] 
          && invoicePaymentStatus !== InvoicePaymentStatus["paid"] && (
          <div className={styles.payCta}>
            <Link to={`/invoice/${invoiceId}/payment`}>
              <Button
                text={t("features.invoice.pay", {
                  amount,
                  currency,
                })}
                intent="primary"
                icon="arrowRight"
                onClick={() => undefined}
                isStatic={false}
              />
            </Link>
            <div>
              <img
                alt={t("common.logoOf", { company: "Swish" })}
                src="/assets/swish.svg"
              />
            </div>
          </div>
        )}

        {/* Link to intrum when in debt collection */}
        {invoiceStatus !== InvoiceStatus["cancelled"] 
          && invoiceStage === InvoiceStage["debtCollection"] 
          && invoicePaymentStatus !== InvoicePaymentStatus["paid"] && (
          <div className={styles.payCta}>
            <Link to="https://intrum.se" target="_blank">
              <Button
                text={t("features.invoice.payIntrum")}
                intent="primary"
                icon="arrowUpRight"
                isStatic={false}
              />
            </Link>
          </div>
        )}
      </div>
    </RenderInvoiceCard>
  );
}
