import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  InvoiceActivity,
  InvoiceHeroDetails,
  InvoiceItems,
} from "features/invoice";
import { EmptyHistory } from "features/invoice/components/InvoiceActivity/EmptyHistory";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";
import { Text } from "components/Text";
import { Tooltip } from "components/Tooltip";

import { copyToClipboard, extractReminderFee } from "lib/helpers";
import { useHead } from "lib/hooks/useHead";
import {
  useGetInvoiceQuery,
  useResendEmailMutation,
} from "lib/slices/invoiceServiceSlice";
import { IRootState } from "lib/store";

import styles from "./PageInvoiceDetails.module.scss";

export function PageInvoiceDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { data: invoice, isFetching } = useGetInvoiceQuery(params.id as string);
  const [resendEmail] = useResendEmailMutation();
  useHead(t("pages.invoiceDetails.title"));

  const language = useSelector((state: IRootState) => state.language.language);

  const [sentEmailStatus, setSentEmailStatus] = useState("initial");

  const handleEmailPDF = async (invoiceId: string) => {
    if (sentEmailStatus === "initial") {
      setSentEmailStatus("loading");

      try {
        const response = await resendEmail(invoiceId).unwrap();
        await new Promise((res) => setTimeout(res, 100));
        if (response === true) {
          setSentEmailStatus("success");
        }
      } catch (error) {
        setSentEmailStatus("initial");
        console.error("Failed to resend email", error);
      }
    }
  };

  let intentValue: "tertiary" | "success" | "disabled" = "disabled";

  if (sentEmailStatus === "initial") {
    intentValue = "tertiary";
  } else if (sentEmailStatus === "loading") {
    intentValue = "disabled";
  } else if (sentEmailStatus === "success") {
    intentValue = "success";
  }

  if (isFetching) {
    return (
      <div className={styles.content}>
        <div className={styles.invoiceDetails}>
          <SkeletonGroup
            wrapperStyles={{
              borderRadius: "16px",
            }}
            options={[
              {
                width: "100%",
                height: "300px",
                borderRadius: "16px",
                marginBottom: "16px",
              },
              {
                width: "100%",
                height: "150px",
                borderRadius: "16px",
                marginBottom: "16px",
              },
              {
                width: "100%",
                height: "450px",
                borderRadius: "16px",
                marginBottom: "16px",
              },
            ]}
          />
        </div>
      </div>
    );
  } else if (!invoice) {
    navigate("/");
    return null;
  } else {
    return (
      <div className={styles.content}>
        <div className={styles.invoiceDetails}>
          <InvoiceHeroDetails
            invoiceId={invoice.id}
            displayId={invoice.invoiceNo}
            amount={invoice.totalAmount}
            currency={invoice.currency}
            dueDate={invoice.dueDate}
            invoiceStage={invoice.stage}
            invoicePaymentStatus={invoice.paymentStatus}
            invoiceStatus={invoice.status}
            language={language}
            reminderFee={extractReminderFee(invoice)}
          />

          <Card>
            <div className={styles.invoiceNumber}>
              <Text as="p" style="body">
                {t("features.invoice.paymentReference")}
              </Text>
              <div>
                <Text as="p" style="body" color="--static-content-subtle">
                  {invoice.paymentReference}
                </Text>
                <Button
                  intent="clear"
                  onClick={() => copyToClipboard(invoice.paymentReference)}
                >
                  <div className={styles.copyBtn}>
                    <Tooltip
                      text={t("common.copied")}
                      showOnClick={true}
                      autoMinWidth={true}
                      position="top"
                    >
                      <Icon
                        name="copy"
                        size={14}
                      />
                    </Tooltip>
                  </div>
                </Button>
              </div>
            </div>
            <div className={styles.invoiceNumber}>
              <Text as="p" style="body">
                {t("features.invoice.bankName")}
              </Text>
              <div>
                <Text as="p" style="body" color="--static-content-subtle">
                  {/* TODO MOCK REMOVE */}
                  {invoice.bankName ?? "Nordea"}
                </Text>
              </div>
            </div>
            <div className={styles.invoiceNumber}>
              <Text as="p" style="body">
                {t("features.invoice.iban")}
              </Text>
              <div>
                <Text as="p" style="body" color="--static-content-subtle">
                  {/* TODO MOCK REMOVE */}
                  {invoice.IBAN ?? "SE1234567890"}
                </Text>
                <Button
                  intent="clear"
                  onClick={() =>
                    copyToClipboard(invoice.IBAN ?? "SE1234567890")
                  }
                >
                  <div className={styles.copyBtn}>
                    <Tooltip
                      text={t("common.copied")}
                      showOnClick={true}
                      autoMinWidth={true}
                      position="top"
                    >
                      <Icon name="copy" size={14} />
                    </Tooltip>
                  </div>
                </Button>
              </div>
            </div>
          </Card>

          <InvoiceItems
            invoiceItems={invoice.invoiceItems}
            vatAmount={invoice.VATAmount}
            currency={invoice.currency}
            language={language}
          />

          {invoice.histories.length > 0 ? (
            <InvoiceActivity
              timeline={invoice.histories}
              language={language}
            />
          ) : (
            <EmptyHistory />
          )}

          <div className={styles.ctaBtns}>
            <a
              href={invoice.pdfURL}
              target="_blank"
              download={`invoice-${invoice.id}`}
              rel="noreferrer"
            >
              <Button
                intent="tertiary"
                text={t("features.invoice.download")}
                icon="download"
                textLeft
              />
            </a>
            <Button
              intent={intentValue}
              disabled={
                intentValue === "disabled" || intentValue === "success"
              }
              text={t("features.invoice.emailPDF")}
              icon={sentEmailStatus !== "success" ? "mail" : "tick"}
              textLeft
              onClick={() => handleEmailPDF(invoice.id)}
            />
          </div>
        </div>
      </div>
    );
  }
}
